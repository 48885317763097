import './App.css'
import Messages from './components/Messages';
function App() {
  return (
    <>
      <Messages />
    </>
  );
}

export default App;
