import anime from "animejs/lib/anime.es.js";
import { useEffect, useRef } from "react";

const Messages = () => {
  const messagesEl = useRef(null);
  const typingSpeed = 20;
  const loadingText =
    '<b class="dot">•</b><b class="dot">•</b><b class="dot">•</b>';
  let messageIndex = 0;

  const getCurrentTime = function () {
    const date = new Date();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const current = hours + minutes * 0.01;
    if (current >= 5 && current < 19) return "Have a nice day";
    if (current >= 19 && current < 22) return "Have a nice evening";
    if (current >= 22 || current < 5) return "Have a good night";
  };

  const messages = [
    "Hi there ✋",
    "I'm Abelant",
    "I code things on the web",
    "I'm currently working as a",
    '👨🏽‍💻 Software Engineer based in 📍London, UK <br> You can contact me at <a href="mailto:abelant@outlook.com">abelant@outlook.com</a>',
    'more about me: <br><a target="_blank" href="https://twitter.com/abelantz">twitter.com/abelantz</a><br><a target="_blank" href="https://github.com/abelant">github.com/abelant</a><br><a target="_blank" href="https://linkedin.com/in/abelant">linkedin.com/in/abelant</a>',
    getCurrentTime(),
    "🤘 AZ.",
  ];

  const getFontSize = function () {
    return parseInt(
      getComputedStyle(document.body).getPropertyValue("font-size")
    );
  };

  const pxToRem = function (px) {
    return px / getFontSize() + "rem";
  };

  const createBubbleElements = function (message, position) {
    const bubbleEl = document.createElement("div");
    const messageEl = document.createElement("span");
    const loadingEl = document.createElement("span");
    bubbleEl.classList.add("bubble");
    bubbleEl.classList.add("is-loading");
    bubbleEl.classList.add("cornered");
    bubbleEl.classList.add(position === "right" ? "right" : "left");
    messageEl.classList.add("message");
    loadingEl.classList.add("loading");
    messageEl.innerHTML = message;
    loadingEl.innerHTML = loadingText;
    bubbleEl.appendChild(loadingEl);
    bubbleEl.appendChild(messageEl);
    bubbleEl.style.opacity = 0;
    return {
      bubble: bubbleEl,
      message: messageEl,
      loading: loadingEl,
    };
  };

  const getDimentions = function (elements) {
    let dimensions = {
      loading: {
        w: "4rem",
        h: "2.25rem",
      },
      bubble: {
        w: pxToRem(elements.bubble.offsetWidth + 4),
        h: pxToRem(elements.bubble.offsetHeight),
      },
      message: {
        w: pxToRem(elements.message.offsetWidth + 4),
        h: pxToRem(elements.message.offsetHeight),
      },
    };
    return dimensions;
  };

  const sendMessage = function (message, position) {
    const loadingDuration =
      message.replace(/<(?:.|\n)*?>/gm, "").length * typingSpeed + 500;
    const elements = createBubbleElements(message, position);
    messagesEl.current.appendChild(elements.bubble);
    messagesEl.current.appendChild(document.createElement("br"));
    const dimensions = getDimentions(elements);
    elements.bubble.style.width = "0rem";
    elements.bubble.style.height = dimensions.loading.h;
    elements.message.style.width = dimensions.message.w;
    elements.message.style.height = dimensions.message.h;
    elements.bubble.style.opacity = 1;
    const bubbleOffset =
      elements.bubble.offsetTop + elements.bubble.offsetHeight;

    anime({
      targets: elements.bubble.querySelectorAll("b"),
      scale: [0.7, 1.25],
      opacity: [0.5, 1],
      duration: 700,
      loop: true,
      direction: "alternate",
      delay: function (el, i) {
        return i * 100 + 50;
      },
    });
    if (bubbleOffset > messagesEl.current.offsetHeight) {
      anime({
        targets: messagesEl.current,
        scrollTop: bubbleOffset,
        duration: 750,
      });
    }
    anime({
      targets: elements.bubble,
      width: ["0rem", dimensions.loading.w],
      marginTop: ["2.5rem", 0],
      marginLeft: ["-2.5rem", 0],
      duration: 800,
      easing: "easeOutElastic",
    });

    const loadingLoop = anime({
      targets: elements.bubble,
      scale: [1.05, 0.95],
      duration: 1100,
      loop: true,
      direction: "alternate",
      easing: "easeInOutQuad",
    });

    setTimeout(() => {
      loadingLoop.pause();
      anime({
        targets: elements.bubble.querySelectorAll("b"),
        opacity: 0,
        scale: 0,
        loop: false,
        direction: "forwards",
        update: function (a) {
          if (
            a.progress >= 65 &&
            elements.bubble.classList.contains("is-loading")
          ) {
            elements.bubble.classList.remove("is-loading");
            anime({
              targets: elements.message,
              opacity: [0, 1],
              duration: 500,
            });
          }
        },
      });
      anime({
        targets: elements.loading,
        opacity: [1, 0.6, 0.4, 0],
      });

      anime({
        targets: elements.bubble,
        scale: 1,
        width: [dimensions.loading.w, dimensions.bubble.w],
        height: [dimensions.loading.h, dimensions.bubble.h],
        marginTop: 0,
        marginLeft: 0,
        begin: function () {
          if (messageIndex < messages.length)
            elements.bubble.classList.remove("cornered");
        },
      });
    }, loadingDuration - 50);
  };

  const sendMessages = function () {
    const message = messages[messageIndex];
    if (!message) return;
    sendMessage(message);
    messageIndex++;
    setTimeout(
      sendMessages,
      message.replace(/<(?:.|\n)*?>/gm, "").length * typingSpeed +
        anime.random(900, 1200)
    );
  };
  useEffect(() => {
    window.onload = () => {
      sendMessages();
    };
  });

  return <div ref={messagesEl} className="messages" />;
};
export default Messages;
